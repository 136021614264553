import React from "react";

import Feature from './FeatureSection'

const AccessibilityMain = () => {


    return (
        <>

            <Feature />
            
        </>
    )
}

export default AccessibilityMain;