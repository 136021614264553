import React from 'react';
import { Link } from 'react-router-dom'

import courseImg1 from '../../assets/images/course/1.png';

const SingleCourse = (props) => {
    const { itemClass, courseID, courseImg, courseTitle, courseName, courseLesson, courseEnrolled, coursePrice } = props;
	return(
        <div className={itemClass ? itemClass : 'course__item mb-30'}>
            <div className="course__thumb">
                <Link to={`/#`}>
                    <img src={courseImg ? require(`../../assets/images/course/${courseImg}`) : require(`../../assets/images/course/${courseImg1}`)} alt="image" />
                </Link>
            </div>
            <div className="course__inner">
                <ul>
                    <li>{courseName ? courseName : 'UX Design'}</li>
                </ul>
                <h3 className="react-course-title">
                    <Link to={`/#`}>
                        {courseTitle ? courseTitle : 'Dave conservatoire is the Entirely free online.'}
                    </Link>
                </h3>
            </div>                                    
        </div>
	)
}

export default SingleCourse