import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import ScrollToTop from '../../components/ScrollTop';
import AccessibilityMain from './AccessibilityMain';

import Logo from '../../assets/images/logos/logo.png';

const Accessibility = () => {

    return (
        <>
            <Header 
                parentMenu='home'
                topbarEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    
                    <Breadcrumb pageTitle="Accessibility" />

                    <AccessibilityMain />

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <Footer />

        </>
    );
}

export default Accessibility;