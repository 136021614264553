import React from 'react';
import Slider from "react-slick";

import SectionTitle from '../../components/SectionTitle'
import SingleTestimonial from '../../components/Testimonial/SingleTestimonial';

import testiImg1 from '../../assets/images/testimonial/testimonial1.png';
import testiImg2 from '../../assets/images/testimonial/testimonial2.png';
import testiImg3 from '../../assets/images/testimonial/testimonial3.png';
import testiImg4 from '../../assets/images/testimonial/testimonial4.png';
import testiImg5 from '../../assets/images/testimonial/testimonial5.png';
import comaImg from '../../assets/images/testimonial/coma.png';

const Testimonial = () => {

    const testimonialSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                }
            },
            {

                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                }
            }
        ]
    };

    return (
        <>
            <div className="react-clients react-clientso home-testimonial pt---90 pb---120">
                <div className="container">
                    <SectionTitle Title="Elevating Student Voices" />
                    <div className="client-slider wow animate__fadeInUp" data-wow-duration="0.3s">
                        <Slider {...testimonialSettings}>
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={testiImg1 }
                                Title="Emma L."
                                Designation="(Class of 2025, Computer Science)"
                                Desc="Terranova University has truly opened doors for me. The hands-on projects and supportive faculty have helped me develop skills I never thought possible, and I feel more prepared than ever to enter the tech industry."
                                ratingCount='4.9'
                                reviewCount='14'
                                comaImg={comaImg}
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={testiImg2 }
                                Title="James P."
                                Designation="(Class of 2024, Business Administration)"
                                Desc="From day one, I felt welcomed into a vibrant, driven community. The connections I’ve made here and the real-world experiences through internships have set me up for success far beyond graduation."
                                ratingCount='4.9'
                                reviewCount='14'
                                comaImg={comaImg}
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={testiImg3 }
                                Title="Sophia R."
                                Designation="(Class of 2026, Environmental Science)"
                                Desc="At Terranova, I’ve been able to explore my passion for sustainability through cutting-edge research and global study opportunities. The university’s commitment to creating change aligns perfectly with my goals."
                                ratingCount='4.9'
                                reviewCount='14'
                                comaImg={comaImg}
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={testiImg4 }
                                Title="David M."
                                Designation="(Class of 2023, History)"
                                Desc="Terranova’s focus on both academics and personal growth has been life-changing. I’ve had the chance to learn from world-class professors while building leadership skills that will stay with me forever."
                                ratingCount='4.9'
                                reviewCount='14'
                                comaImg={comaImg}
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={testiImg5 }
                                Title="Alyssa K."
                                Designation="(Class of 2025, Psychology)"
                                Desc="Terranova University offers a perfect balance of academic rigor and personal support. The small class sizes and close-knit community have allowed me to connect with professors and peers in meaningful ways."
                                ratingCount='4.9'
                                reviewCount='14'
                                comaImg={comaImg}
                            />
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Testimonial