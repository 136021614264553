
import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/logos/footer-logo.png';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <>
            <footer id="react-footer" className={footerClass ? footerClass : 'react-footer home-main'}>
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 md-mb-30">
                                <div className="footer-widget footer-widget-1">
                                    <div className="footer-logo white">
                                        <Link to="/" className="logo-text"><img src={footerLogo ? footerLogo : Logo} alt="Footer Logo" /></Link>
                                    </div>
                                    <h5 className="footer-subtitle"><em>Sapientia Nova Terris</em><br/>
                                    New Knowledge for the World</h5>
                                    <h5 className="footer-subtitle">The Terranova motto conveys our commitment to discovery, learning, and global impact.</h5>               
                                </div>
                            </div>
                            <div className="col-lg-3 md-mb-30">
                                <div className="footer-widget footer-widget-2">
                                    <h3 className="footer-title">About Terranova</h3>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to="#">'Nova Spirit</Link></li>
                                            <li><Link to="#">Programs & Courses</Link></li>
                                            <li><Link to="#">Events</Link></li>
                                            <li><Link to="#">Terranova Careers</Link></li>
                                            <li><Link to="#">Admissions</Link></li>
                                            <li><Link to="#">Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 md-mb-30">
                                <div className="footer-widget footer-widget-3">
                                    <h3 className="footer-title">Popular Resources</h3>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to="#">My Terranova</Link></li>
                                            <li><Link to="#">Library</Link></li>
                                            <li><Link to="#">Athletics</Link></li>
                                            <li><Link to="#">News & Events</Link></li>
                                            <li><Link to="#">Emergency Info</Link></li>
                                            <li><Link to="#">Student Services</Link></li>
                                            <li><Link to="/accessibility">Accessibility</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="footer-widget footer-widget-4">
                                    <h3 className="footer-title">Email Updates</h3> 
                                    <div className="footer3__form">
                                        <p>Subscribe to the 'Nova News!</p>
                                        <form action="#">
                                            <input type="email" required placeholder="Enter your email" />
                                            <button className="footer3__form-1">
                                                <i className="arrow_right"></i>
                                            </button>
                                        </form>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyright">  
                    <div className="container">                  
                        <div className="react-copy-left">Demo updated September 2024</div>
                        <div className="react-copy-right">
                            <ul className="social-links">
                                <li className="follow">Follow us</li>
                                <li><Link to="#"><span aria-hidden="true" className="social_facebook"></span></Link></li>
                                <li><Link to="#"><span aria-hidden="true" className="social_twitter"></span></Link></li>
                                <li><Link to="#"><span aria-hidden="true" className="social_linkedin"></span></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;